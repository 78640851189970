.container__default-sbdpe{
    margin: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 500px;
height: 100vh;
}
.container__default-sbdpe p{
    margin-bottom: 15px;
}