@import "normalize";
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
html{
  line-height: 2.15;
}
.radioAdjust {
  margin-right: 3px;
}
.gender__input {
  margin-top: 5px;
}
.desabled {
  display: none;
}
body {
  /*background-image: "c";*/  
  font-family: 'Montserrat', sans-serif;
  min-width: 305px;
}
a {
  text-decoration: none;
  color: inherit;
  transition: 0.5s;
}
a:hover {
  color: #fe7917;
}
label {
  display: block;
}
.button__default {
  font-weight: bold;
  padding: 7px;
  border-radius: 5px;
  background-color: #ccccd6;
  transition: 0.5s;
  border: none;
  color: #777;
  cursor: pointer;
  float: right;
  margin-left: 10px;
  transition: background-color 0s;
  transition: color 0.5s;
  box-shadow: 0px 0px 20px #fff inset;
}

.button__default:hover {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.075) inset;
}

.button__default--action {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px -20px 20px rgba(0, 0, 0, 0.075) inset;
  font-weight: bold;
  padding: 7px;
  border-radius: 5px;
  transition: 0.5s;
  border: none;
  cursor: pointer;
  float: right;
  margin-left: 0 !important;
  transition: background-color 0s;
  transition: color 0.5s;
  margin-bottom: 0 important!;
}

.button__default:active {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px 0px 2px 1px #fff;
}

.button__default--action:active {
  background-color: #fe7917;
  color: white;
  box-shadow: 0px 0px 2px 1px #fff;
}

.input__default {
  padding: 5px 10px;
  border-radius: 2px;
  border: none;
  width: 100%;
  /* box-shadow: 0px 0px 2px #000 inset; */
  /* margin-bottom: 10px; */
}
.input__default:disabled {
  background-color: white;
  opacity: 0.5;
}
.input__default--twoDig:disabled {
  background-color: white;
  opacity: 0.5;
}

.form__conteinerFlex {
  display: flex;
  justify-content: space-evenly;
}
iframe {
  border: none;
}
.successtext_register {
  text-align: center;
  padding: 0px 85px;
  line-height: 24px;
}

.checks {
  display: flex;
  /* gap: 1rem; */
  margin: 1.5rem 0;
  flex-direction: column;
  align-items: baseline;
}
/* .checks p {
  line-height: 1.5rem;
} */
.newsletter,
.policy {
  width: 100%;
  display: flex;
  align-items: center;
  -webkit-box-align: center;
  font-size: 1.02rem;
}
.policy a {
  font-weight: bold;
  text-decoration: underline;
}
#newsletter,
#policy {
  width: 15px;
  margin-right: .4rem;
  border-radius: 2px;
  border-bottom: 1px solid #727377 
}
.btn_isLinked {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

 @media only screen and (max-width: 465px) {
   .policy {
     align-items: baseline;
     -webkit-box-align: center;
    }
    .newsletter, .policy {
      align-items: baseline;
    }      
  }

.btn-login-plataforma {
  float: unset !important;
  margin-top: 20px;
}

#background-video {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: -1;
}
